<template>
  <div>
    <portal to="header">
      <terminal-title :text="$t('Dashboard')" />
    </portal>

    <q-tabs
      v-model="tab"
      dense
      class="text-grey"
      active-color="primary"
      indicator-color="primary"
      align="justify"
      narrow-indicator
      no-caps
    >
      <q-tab name="tasks" :label="$t('Picking tasks')" />

      <q-tab name="items" :label="$t('Products')" />
    </q-tabs>

    <q-separator />

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="tasks">
        <picking-tasks-panel />
      </q-tab-panel>

      <q-tab-panel name="items">
        <storage-items-panel />
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script>
// Components
import PickingTasksPanel from '../components/panels/PickingTasksPanel.vue'
import StorageItemsPanel from '../components/panels/StorageItemsPanel.vue'

export default {
  name: 'Dashboard',
  components: {
    PickingTasksPanel,
    StorageItemsPanel
  },
  data () {
    return {
      tab: 'tasks',
      userdata: JSON.parse(localStorage.getItem('userData'))
    }
  },
  methods: {
  }
}
</script>
