<template>
  <div>
    <q-card
      class="q-px-sm row items-center q-mb-sm"
      :class="color"
    >
      <div class="col">
        <div class="text-subtitle1">
          {{ $t('Tasks for today') }}
        </div>

        <div class="text-h6 text-bold">
          {{ tasks.complete.length + '/' + (tasks.total > 250 ? 250 : tasks.total) }}
        </div>

        <div v-if="tasks.total > 250" class="text-caption">
          {{ `${$t('Their is')} ${tasks.total - 250} ${$t('tasks. You can see more info in app.')}` }}
        </div>
      </div>

      <div class="row items-center justify-center">
        <q-knob
          show-value
          font-size="12px"
          :model-value="completedTasks"
          size="80px"
          :thickness="0.22"
          color="orange-10"
          class="q-ma-md"
        >
          <span class="text-bold">
            {{ completedTasks }}%
          </span>
        </q-knob>
      </div>
    </q-card>

    <div class="row items-center q-pa-sm">
      <q-avatar
        color="red"
        text-color="white"
        icon="cloud_upload"
        class="q-mr-md"
      />

      <div>
        <div class="text-subtitle2 text-bold">
          {{ $t('To do') }}
        </div>

        <div class="text-caption text-grey">
          <span class="text-bold text-dark">
            {{ tasks.new.length }}
          </span>

          <span>
            {{ $t('tasks now') }}
          </span>
        </div>
      </div>
    </div>

    <div class="row items-center q-pa-sm">
      <q-avatar
        color="secondary"
        text-color="white"
        icon="autorenew"
        class="q-mr-md"
      />

      <div>
        <div class="text-subtitle2 text-bold">
          {{ $t('In progress') }}
        </div>

        <div class="text-caption text-grey">
          <span class="text-bold text-dark">
            {{ tasks.processing.length }}
          </span>

          <span>
            {{ $t('tasks started') }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="tasks.rejected && tasks.rejected.length > 0" class="row items-center q-pa-sm">
      <q-avatar
        color="red"
        text-color="white"
        icon="skip_next"
        class="q-mr-md"
      />

      <div>
        <div class="text-subtitle2 text-bold">
          {{ $t('Skipped') }}
        </div>

        <div class="text-caption text-grey">
          <span class="text-bold text-dark">
            {{ tasks.rejected.length }}
          </span>

          <span>
            {{ $t('tasks') }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="tasks.canceled && tasks.canceled.length > 0" class="row items-center q-pa-sm">
      <q-avatar
        color="negative"
        text-color="white"
        icon="close"
        class="q-mr-md"
      />

      <div>
        <div class="text-subtitle2 text-bold">
          {{ $t('Canceled') }}
        </div>

        <div class="text-caption text-grey">
          <span class="text-bold text-dark">
            {{ tasks.canceled.length }}
          </span>

          <span>
            {{ $t('tasks') }}
          </span>
        </div>
      </div>
    </div>

    <div class="row items-center q-pa-sm">
      <q-avatar
        color="positive"
        text-color="white"
        icon="done"
        class="q-mr-md"
      />

      <div>
        <div class="text-subtitle2 text-bold">
          {{ $t('Completed') }}
        </div>

        <div class="text-caption text-grey">
          <span class="text-bold text-dark">
            {{ tasks.complete.length }}
          </span>

          <span>
            {{ $t(`${tasks.complete.length === 1 ? 'task' : 'tasks'} finished`) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PickingTasksPanel',
  data () {
    return {
      tasks: {
        complete: [],
        new: [],
        processing: [],
        total: 0
      }
    }
  },
  computed: {
    color () {
      if (this.tasks.complete.length >= this.tasks.total) {
        return 'bg-positive'
      }

      if (this.tasks.complete.length <= 0) {
        return 'bg-negative'
      }

      return 'bg-yellow-4'
    },
    completedTasks () {
      if (this.tasks.total <= 0) {
        return 100
      }

      const total = this.tasks.total > 250
        ? 250
        : this.tasks.total

      return Math.floor(this.tasks.complete.length / total * 100)
    }
  },
  mounted () {
    this.loadTasks()
  },
  methods: {
    loadTasks () {
      const userdata = JSON.parse(localStorage.getItem('userData'))
      const query = {
        per_page: 250,
        page: 1,
        filter: [
          { type: 'eq', field: 'executive', value: userdata.id },
          { type: 'gte', field: 'created', value: this.$utils.getDate() }
        ]
      }

      return this.$service.pickingTask.getAll(query)
        .then(({ items, totalItems }) => {
          this.tasks = items.reduce((acc, item) => {
            if (!acc[item.state]) {
              acc[item.state] = []
            }

            acc[item.state].push(item)
            return acc
          }, { total: totalItems, complete: [], new: [], processing: [] })
        })
    }
  }
}
</script>
