<template>
  <div>
    <q-card
      class="q-px-sm row items-center q-mb-sm"
      :class="color"
    >
      <div class="col">
        <div class="text-subtitle1">
          {{ $t('Products for today') }}
        </div>

        <div class="text-h6 text-bold">
          {{ acceptedItems + '/' + totalItems }}
        </div>
      </div>

      <div class="row items-center justify-center">
        <q-knob
          show-value
          font-size="12px"
          :model-value="completed"
          size="80px"
          :thickness="0.22"
          color="orange-10"
          class="q-ma-md"
        >
          <span class="text-bold">
            {{ completed }}%
          </span>
        </q-knob>
      </div>
    </q-card>

    <div class="row items-center q-pa-sm">
      <q-avatar
        color="red"
        text-color="white"
        icon="downloading"
        class="q-mr-md"
      />

      <div>
        <div class="text-subtitle2 text-bold">
          {{ $t('Expect') }}
        </div>

        <div class="text-caption text-grey">
          <span>
            {{ $t('Expect') + ' ' }}
          </span>

          <span class="text-bold text-dark">
            {{ expectedItems }}
          </span>

          <span>
            {{ $t(`${expectedItems === 1 ? 'product' : 'products'}`) }}
          </span>
        </div>
      </div>
    </div>

    <div class="row items-center q-pa-sm">
      <q-avatar
        color="secondary"
        text-color="white"
        icon="download"
        class="q-mr-md"
      />

      <div>
        <div class="text-subtitle2 text-bold">
          {{ $t('Received') }}
        </div>

        <div class="text-caption text-grey">
          <span class="text-bold text-dark">
            {{ acceptedItems }}
          </span>

          <span>
            {{ $t(`${acceptedItems === 1 ? 'product' : 'products'}`) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StorageItemsPanel',
  data () {
    return {
      expectedItems: 0,
      acceptedItems: 0,
      totalItems: 0
    }
  },
  computed: {
    color () {
      if (this.acceptedItems >= this.totalItems) {
        return 'bg-positive'
      }

      if (this.acceptedItems.length <= 0) {
        return 'bg-negative'
      }

      return 'bg-yellow-4'
    },
    completed () {
      if (this.totalItems <= 0) {
        return 100
      }

      return Math.floor(this.acceptedItems / this.totalItems * 100)
    }
  },
  mounted () {
    this.getItemsByState('expected')
      .then(({ totalItems }) => {
        this.expectedItems = totalItems

        this.getItemsByState('normal')
          .then(({ totalItems }) => {
            this.acceptedItems = totalItems

            this.totalItems = this.expectedItems + this.acceptedItems
          })
      })
  },
  methods: {
    getItemsByState (value) {
      const query = {
        per_page: 1,
        page: 1,
        filter: [
          { type: 'gte', field: 'created', value: this.$utils.getDate() }
        ]
      }

      if (value) {
        query.filter.push({ type: 'eq', field: 'state', value })
      }

      return this.$service.storageItemEntity.getAll(query)
    }
  }
}
</script>
